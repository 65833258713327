





















import { Component, Vue, Prop } from 'vue-property-decorator'
import * as model from '../models'
import { utils } from '@/utils/utils'
import { time_utils } from '@/utils/time_utils'
import sweepCodeDialog from '@/modules/common/components/sweep-code-dialog.vue';
import { isSupportType, getContentTypeSn } from '@/config/typeConfig'

@Component({
  components: {
    sweepCodeDialog
  }
})
export default class ListExhibition extends Vue {
  @Prop({ default: {} }) info!: model.ListExhibition
  @Prop({ default: true }) showType!: boolean
  @Prop({ default: true }) showSubscribe!: boolean

  stateTxt: Array<object> = [
    {key: '未开始', style: 'no_start'},
    {key: '直播中', style: 'liveing'},
    {key: '看回放', style: 'live_end'}
  ]

  dialogOption: object = {
    showDialog: false,
    type: '',
    contentId: '',
    title: '更多精彩内容请访问移动端店铺'
  }

  mounted() {
  }

  get isLive() {
    const info: any = this.info
    const type = info.type
    let isLive = false
    switch (type) {
      case 'live':
      case 'content_live':
      case 'class_live':
        isLive = true
        break
    }
    return isLive
  }

  get isSupportType() {
    const type = this.info.type
    return isSupportType(type)
  }

  get getImgSrc() {
    return utils.createImgSrc(this.info.indexpic, { width: 440 })
  }

  get getInfo() {
    const info = this.info
    const type = info.type
    const typeLabel = getContentTypeSn(type)
    const subscribeCount = utils.numberShowRules1(info.subscribe_count)
    const commentCount = utils.numberShowRules1(info.comment_count)
    const classCount = utils.numberShowRules1(info.class_count)

    let infoTips = ''
    switch (type) {
      case 'article':
      case 'audio':
      case 'video':
        infoTips = `${this.showSubscribe && this.shopShowData.sub ? subscribeCount + '人在学 | ' : ''}${commentCount}评论${info.update_time ? ' | ' + info.update_time + '更新' : ''} | ${this.getExpireTime()}`
        break
      case 'live':
        infoTips = `${time_utils.formatDate(info.start_timestamp)}开播 | ${this.getExpireTime()}`
        break
      case 'course':
        infoTips = `课时${classCount} | ${this.showSubscribe && this.shopShowData.sub ?  '订阅 | ' + subscribeCount : ''} ${this.getExpireTime()}`
        // infoTips = `课时${classCount} | 订阅${subscribeCount} | ${this.getExpireTime()}`
        break
      case 'trainingcampclass':
        const start = time_utils.formatDate(info.start_time, 'MM.dd')
        const end = time_utils.formatDate(info.end_time, 'MM.dd')
        infoTips = `开课时间：${start} - ${end} | ${this.getExpireTime()}`
        break
      default:
        infoTips = ''
        break
    }
    return infoTips
  }

  get shopShowData() {
    return this.$store.state.shopShowData
  }

  getExpireTime() {
    const info = this.info
    let expireTime = ''
    if (!utils.isBlank(info.expire_time)) {
      let timeStamp = time_utils.getTimeStamp(info.expire_time)
      let NowStamp = time_utils.getNowStamp()
      let nTime = timeStamp - NowStamp
      let day = Math.floor(nTime / 86400000)
      let yearDay = 7300
      if (nTime <= 0) {
        expireTime = '已失效'
      } else if (day < 1) {
        expireTime = '即将失效'
      } else if (day >= yearDay) {
        expireTime = '永久有效'
      } else {
        expireTime = day + '天后失效'
      }
    } else {
      expireTime = '永久有效'
    }
    return expireTime
  }

  toDetail() {
    const type = this.info.type
    switch (type) {
      case 'article':
      case 'audio':
      case 'video':
      case 'live':
        this.$router.push({ name: 'content', params: { type, id: this.info.content_id } })
        break
      case 'course':
        this.$router.push({ name: 'course', params: { id: this.info.content_id } })
        break
      default:
        this.dialogOption = {
          showDialog: true,
          type: this.info.type,
          contentId: this.info.content_id,
          title: '更多精彩内容请访问移动端店铺'
        }
        break
    }
  }
}
