









import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

@Component({
})
export default class SegmentTab extends Vue {
  @Prop({ default: [] }) tabs!: Array<Object>
  @Prop({ default: 0 }) activeIndex!: number
  selectIndex: number = 0
  @Watch('activeIndex', { immediate: true })
  onActiveIndex() {
    this.selectIndex = this.activeIndex
  }
  toggleHandler(index: number) {
    if (this.selectIndex !== index) {
      this.selectIndex = index
      this.$emit('tabs-change', this.selectIndex)
    }
  }
}
