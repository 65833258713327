























import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { getSubscribeLists } from '../api'
import * as model from '../models'
import listExhibition from '../components/list-exhibition.vue'
import dsPagination from '../components/ds-pagination.vue'
import contentBox from '../components/content-box.vue'
import segmentTab from '../components/segment-tab.vue'

interface TabsInfo {
  title: string
  sort: string
}

@Component({
  components: {
    listExhibition,
    dsPagination,
    contentBox,
    segmentTab
  }
})
export default class SubscribeList extends Vue {
  @Watch('$route')
  onRouteChange(to: any, from: any) {
    this.renderSubscribeList()
  }

  activeIndex: number = 0
  tips: string = 'PC端暂时只支持浏览课程、图文、音频、视频、直播，其他类型内容需使用微信扫码查看'
  isLoading: boolean = true
  subscribeList: Array<model.Subscribe> = []
  subscribeStyle: object = {
    'width': '100%',
    'display': 'flex',
    'flex-direction': 'column',
    'align-items': 'center'
  }
  tabs: Array<TabsInfo> = [
    {
      title: '最近更新',
      sort: '-update_time'
    },
    {
      title: '最近购买',
      sort: '-order_time'
    },
    {
      title: '最近浏览',
      sort: '-view_time'
    }
  ]
  contentType: string = ''
  componentId: string | undefined
  total: number = 0
  page: number = 1
  count: number = 10
  order_by: string = '-update_time'

  get isHaveData() {
    return this.subscribeList.length
  }

  get getActiveIndex() {
    let i = 0
    this.tabs.forEach((m, n) => {
      if (m.sort === this.order_by) {
        i = n
      }
    })
    return i
  }
  mounted() {
    window.scrollTo(0, 0)
    this.renderSubscribeList()
  }

  renderSubscribeList() {
    this.page = Number(this.$route.query.page) || 1
    this.count = Number(this.$route.query.count) || 10
    this.order_by = this.$route.query.order_by ? String(this.$route.query.order_by) : '-update_time'
    this.activeIndex = this.getActiveIndex
    this.getSubscribeList()
  }

  getSubscribeList() {
    this.isLoading = true
    getSubscribeLists({
      page: this.page,
      count: this.count,
      order_by: this.order_by
    }).then(data => {
      this.subscribeList = data.data
      this.total = data.page.total
      this.isLoading = false
    }).catch(res => {
      this.subscribeList = []
      this.total = 0
      this.isLoading = false
      console.error('getSubscribeLists = ', res)
    })
  }

  getListExhibitionItem(item: model.Subscribe) {
    let listExhibition: model.ListExhibition = {
      content_id: item.content_id,
      type: item.type || item.content_type || '',
      title: item.title || item.content_title || '',
      brief: item.brief,
      indexpic: item.indexpic,
      update_time: item.update_time,
      comment_count: item.comment_count,
      subscribe_count: item.subscribe,
      class_count: item.hour_count,
      start_time: item.start_time,
      end_time: item.end_time,
      expire_time: item.expire_time,
      live_state: item.live_state,
      start_timestamp: item.start_timestamp
    }
    return listExhibition
  }

  onPageChange(val: number) {
    this.$router.push({
      name: 'subscribe',
      query: {
        page: `${val}`,
        count: `${this.count}`,
        order_by: `${this.order_by}`
      }
    })
  }

  onChangeTab(val: number) {
    this.page = 1
    this.count = 10
    this.order_by = this.tabs[val].sort
    this.$router.push({
      name: 'subscribe',
      query: {
        page: `${this.page}`,
        count: `${this.count}`,
        order_by: `${this.order_by}`
      }
    })
  }
}
